import {
  GET_PAYMENTS_WITHOUT_INVOICE,
  PAYMENTS_WITHOUT_INVOICE_ERROR,
  GET_PAYMENTS_WITHOUT_INVOICE_OBSERVATIONS,
} from 'src/actions/types'

const initialState = {
  paymentsWithoutInvoice: {
    data: [],
    current_page: 1,
  },
  filters: {},
  loading: true,
  error: {},
  loadingObservations: false,
  observations: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_PAYMENTS_WITHOUT_INVOICE:
      return {
        ...state,
        paymentsWithoutInvoice: payload,
        filters: payload.filters,
        loading: false,
      }

    case GET_PAYMENTS_WITHOUT_INVOICE_OBSERVATIONS:
      return {
        ...state,
        observations: payload,
        loadingObservations: false,
      }

    case PAYMENTS_WITHOUT_INVOICE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    default:
      return state
  }
}
