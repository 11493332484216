//authentication
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const AUTH_ERROR = 'AUTH_ERROR'
export const GET_USER = 'GET_USER'
export const PROFILE_PICTURE = 'PROFILE_PICTURE'

// departments
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS'
export const ADD_DEPARTMENT = 'ADD_DEPARTMENT'
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT'
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT'
export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR'
export const SELECT_DEPARTMENTS = 'SELECT_DEPARTMENTS'

// banks
export const GET_BANKS = 'GET_BANKS'
export const GET_BANK = 'GET_BANK'
export const ADD_BANK = 'ADD_BANK'
export const UPDATE_BANK = 'UPDATE_BANK'
export const DELETE_BANK = 'DELETE_BANK'
export const BANK_ERROR = 'BANK_ERROR'
export const EXPORT_BANK_EXCEL = 'EXPORT_BANK_EXCEL'
export const SELECT_BANKS = 'SELECT_BANKS'

// providers
export const GET_PROVIDERS = 'GET_PROVIDERS'
export const GET_PROVIDER = 'GET_PROVIDER'
export const ADD_PROVIDER = 'ADD_PROVIDER'
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER'
export const DELETE_PROVIDER = 'DELETE_PROVIDER'
export const PROVIDER_ERROR = 'PROVIDER_ERROR'
export const EXPORT_PROVIDER_EXCEL = 'EXPORT_PROVIDER_EXCEL'
export const SELECT_PROVIDERS = 'SELECT_PROVIDERS'
export const SELECT_ACCOUNT_PROVIDERS = 'SELECT_ACCOUNT_PROVIDERS'
export const DELETE_PROVIDER_ACCOUNT = 'DELETE_PROVIDER_ACCOUNT'

//quotes
export const GET_QUOTES = 'GET_QUOTES'
export const GET_QUOTE = 'GET_QUOTE'
export const ADD_QUOTE = 'ADD_QUOTE'
export const UPDATE_QUOTE = 'UPDATE_QUOTE'
export const DELETE_QUOTE = 'DELETE_QUOTE'
export const EXPORT_QUOTE_EXCEL = 'EXPORT_QUOTE_EXCEL'
export const QUOTE_ERROR = 'QUOTE_ERROR'
export const GET_QUOTE_OBSERVATIONS = 'GET_QUOTE_OBSERVATIONS'
export const ADD_QUOTE_OBSERVATION = 'ADD_QUOTE_OBSERVATION'
export const SEND_PAY_QUOTE = 'SEND_PAY_QUOTE'

// QuoteFiles
export const UPLOAD_QUOTE_FILE_PROGRESS = 'UPLOAD_QUOTE_FILE_PROGRESS'
export const UPLOAD_QUOTE_FILE = 'UPLOAD_QUOTE_FILE'
export const FILE_QUOTE_ERROR = 'FILE_QUOTE_ERROR'

//quoteConcepts
export const GET_QUOTE_CHARGES = 'GET_QUOTE_CHARGES'
export const GET_QUOTE_CONCEPTS = 'GET_QUOTE_CONCEPTS'
export const QUOTE_CONCEPT_ERROR = 'QUOTE_CONCEPT_ERROR'

//units
export const GET_UNIT_LINES = 'GET_UNIT_LINES'
export const GET_UNITS = 'GET_UNITS'
export const UNIT_ERROR = 'UNIT_ERROR'

//purchaseRequest
export const GET_PURCHASE_REQUESTS = 'GET_PURCHASE_REQUESTS'
export const GET_PURCHASE_REQUEST = 'GET_PURCHASE_REQUEST'
export const ADD_PURCHASE_REQUEST = 'ADD_PURCHASE_REQUEST'
export const UPDATE_PURCHASE_REQUEST = 'UPDATE_PURCHASE_REQUEST'
export const DELETE_PURCHASE_REQUEST = 'DELETE_PURCHASE_REQUEST'
export const PURCHASE_REQUEST_ERROR = 'PURCHASE_REQUEST_ERROR'
export const GET_PURCHASE_REQUEST_OBSERVATIONS = 'GET_PURCHASE_REQUEST_OBSERVATIONS'
export const ADD_PURCHASE_REQUEST_OBSERVATION = 'ADD_PURCHASE_REQUEST_OBSERVATION'
export const EXPORT_PURCHASE_REQUEST_EXCEL = 'EXPORT_PURCHASE_REQUEST_EXCEL'

//pendingPayments
export const GET_PENDING_PAYMENTS = 'GET_PENDING_PAYMENTS'
export const PENDING_PAYMENTS_ERROR = 'PENDING_PAYMENTS_ERROR'

//paymentsWithoutInvoice
export const GET_PAYMENTS_WITHOUT_INVOICE = 'GET_PAYMENTS_WITHOUT_INVOICE'
export const UPDATE_PAYMENT_WITHOUT_INVOICE = 'UPDATE_PAYMENT_WITHOUT_INVOICE'
export const PAYMENTS_WITHOUT_INVOICE_ERROR = 'PAYMENTS_WITHOUT_INVOICE_ERROR'
export const GET_PAYMENTS_WITHOUT_INVOICE_OBSERVATIONS = 'GET_PAYMENTS_WITHOUT_INVOICE_OBSERVATIONS'

// files
export const GET_FILES = 'GET_FILES'
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS'
export const UPDATE_FILE = 'UPDATE_FILE'
export const DELETE_FILE = 'DELETE_FILE'
export const FILE_ERROR = 'FILE_ERROR'

// users
export const GET_USERS = 'GET_USERS'
export const ADD_USER = 'ADD_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const ASSIGN_MODULE_USER = 'ASSIGN_MODULE_USER'
export const ASSIGN_PERMISSION_USER = 'ASSIGN_PERMISSION_USER'
export const GET_RECEIVERS = 'GET_RECEIVERS'
export const USER_ERROR = 'USER_ERROR'

// permissions
export const GET_PERMISSIONS = 'GET_PERMISSIONS'
export const PERMISSION_ERROR = 'PERMISSION_ERROR'

// roles
export const GET_ROLES = 'GET_ROLES'
export const ROLE_ERROR = 'ROLE_ERROR'
export const SELECT_ROLES = 'SELECT_ROLES'

//dropbox
export const GET_AUTHORIZATION_CODE = 'GET_AUTHORIZATION_CODE'
export const DROPBOX_ERROR = 'DROPBOX_ERROR'

// payed payments
export const GET_PAYED_PAYMENTS = 'GET_PAYED_PAYMENTS'
export const PAYED_PAYMENTS_ERROR = 'PAYED_PAYMENTS_ERROR'
export const GET_PAYED_PAYMENTS_OBSERVATIONS = 'GET_PAYED_PAYMENTS_OBSERVATIONS'
export const CREDIT_PURCHASE_REQUEST = 'CREDIT_PURCHASE_REQUEST'
export const APPROVE_PAYMENT_PR = 'APPROVE_PAYMENT_PR'
export const AUTHORIZE_PAYMENT_PR = 'AUTHORIZE_PAYMENT_PR'
export const PAY_PAYMENT_PR = 'PAY_PAYMENT_PR'
