import {
  GET_PAYED_PAYMENTS,
  PAYED_PAYMENTS_ERROR,
  GET_PAYED_PAYMENTS_OBSERVATIONS,
} from 'src/actions/types'

const initialState = {
  payedPayments: {
    data: [],
    current_page: 1,
  },
  filters: {},
  loading: true,
  error: {},
  loadingObservations: false,
  observations: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_PAYED_PAYMENTS:
      return {
        ...state,
        payedPayments: payload,
        filters: payload.filters,
        loading: false,
      }

    case GET_PAYED_PAYMENTS_OBSERVATIONS:
      return {
        ...state,
        observations: payload,
        loadingObservations: false,
      }

    case PAYED_PAYMENTS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    default:
      return state
  }
}
